window.larsen ||= {}

larsen.menu =
  setup: ->
    @add_listeners()

  add_listeners: ->
    return false unless document.querySelectorAll('.menu_toggle').length > 0

    document.querySelector('.menu_toggle').addEventListener 'click', (e) ->
      e.preventDefault()
      e.srcElement.classList.toggle('active')

      tl = gsap.timeline()

      if e.srcElement.classList.contains('active')
        tl.to(".menu_toggle .menu_top", {duration: 0.2, y: 10}, 0)
        tl.to(".menu_toggle .menu_bottom", {duration: 0.2, y: -10}, 0)
        
        tl.to(".menu_toggle .menu_middle_1", {duration: 0.2, rotate: -45, transformOrigin: 'center center'}, 0.2)
        tl.to(".menu_toggle .menu_middle_2", {duration: 0.2, rotate: 45, transformOrigin: 'center center'}, 0.2)
        tl.to(".menu_toggle .menu_top", {duration: 0.2, x: -48, y: 10}, 0.3)
        tl.to(".menu_toggle .menu_bottom", {duration: 0.2, x: 48, y: -10}, 0.3)

      else
        tl.to(".menu_toggle .menu_top", {duration: 0.2, x: 0, y: 10}, 0)
        tl.to(".menu_toggle .menu_bottom", {duration: 0.2, x: 0, y: -10}, 0)
        tl.to(".menu_toggle .menu_middle_1", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0.1)
        tl.to(".menu_toggle .menu_middle_2", {duration: 0.2, rotate: 0, transformOrigin: 'center center'}, 0.1)

        tl.to(".menu_toggle .menu_top", {duration: 0.2, y: 0}, 0.3)
        tl.to(".menu_toggle .menu_bottom", {duration: 0.2, y: 0}, 0.3)

      document.querySelector('body').classList.toggle('menu_open')
      e.srcElement.blur()

    document.querySelectorAll('.section_toggle').forEach (link) ->
      link.addEventListener 'click', (e) ->
        e.preventDefault()

        link.classList.toggle('active')

        if link.classList.contains('active')
          link.nextElementSibling.style.maxHeight = "#{link.nextElementSibling.scrollHeight}px"
        else
          link.nextElementSibling.style.maxHeight = 0

        link.closest('li').classList.toggle('open')
        link.blur()

    document.querySelectorAll('.dropdown_container.open').forEach (open_dropdown) ->
      setTimeout ->
        open_dropdown.style.maxHeight = "#{open_dropdown.scrollHeight}px"
      , 200
    
    if window.innerWidth < 849
      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children').forEach (link) ->
        setTimeout ->
          span = link.querySelector('span')
          section_toggle = link.closest('li').querySelector('.section_toggle')
          section_toggle.style.left = "#{span.offsetWidth + 24}px"
        , 200

    if window.innerWidth > 849
      document.querySelectorAll('.dropdown_container').forEach (dropdown) ->
        dropdown.classList.remove('open')

      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children').forEach (link) ->
        if link.getBoundingClientRect().left + 132 >= window.innerWidth
          link.parentNode.querySelector('.dropdown_container').classList.add('reverse')
      
      document.querySelectorAll('.primary_nav .menu_link.level_1.has_children, .primary_nav .section_toggle').forEach (link) ->
        
        link.addEventListener 'mouseenter', (e) ->
          e.target.parentNode.querySelector('.dropdown_container').classList.add('open')

        link.addEventListener 'focus', (e) ->
          e.target.parentNode.querySelector('.dropdown_container').classList.add('open')
        
        link.addEventListener 'mouseleave', (e) ->
          e.target.parentNode.querySelector('.dropdown_container').classList.remove('open')

      document.querySelectorAll('.primary_nav .dropdown_container').forEach (link) ->
        link.addEventListener 'mouseenter', (e) ->
          e.target.classList.add('open')

      document.querySelectorAll('.primary_nav .dropdown_container').forEach (link) ->
        link.addEventListener 'mouseleave', (e) ->
          e.target.classList.remove('open')