import './vendor/gsap.min.js'
import './vendor/ScrollTrigger.min.js'
import './tictoc/environment.js.erb'
import './tictoc/preview.js'
import './larsen/cookie_notice.js.coffee'
import './larsen/age_gate.js.coffee'
import './larsen/menu.js.coffee'
import './larsen/image_block.js.coffee'
import './tictoc/ui.js'

var ready;

ready = function (callback) {
  if (document.readyState !== 'loading') {
    callback();
  } else if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', callback);
  } else {
    document.attachEvent('onreadystatechange', function () {
      if (document.readyState === 'complete') {
        callback();
      }
    });
  }
};

ready(function () {
  tictoc.preview.setup();
  tictoc.ui.setup();
  larsen.cookie_notice.setup()
  larsen.age_gate.setup()
  larsen.menu.setup()
  larsen.image_block.setup()
});